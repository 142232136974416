// import React from "react";
// import { useMyAppWideContext } from "context/my-app-wide-context";

// const PracticeAreasOverviewAccordion = () => {
//   const context = useMyAppWideContext();
//   let selectedPracticeAreaIndex = "";
//   let dispatch = "";
//   if (context) selectedPracticeAreaIndex = context.selectedPracticeAreaIndex;
//   if (context) dispatch = context.dispatch;

//   return (
//     <section>
//       PracticeAreasOverviewAccordion
//       <div>
//         <p>{`Selected practice area index is ${selectedPracticeAreaIndex}`}</p>
//         <ul>
//           <li>
//             <button
//               onClick={() =>
//                 dispatch({ type: "SET_PRACTICE_AREA_INDEX", payload: 0 })
//               }
//             >
//               ZERO
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() =>
//                 dispatch({ type: "SET_PRACTICE_AREA_INDEX", payload: 1 })
//               }
//             >
//               ONE
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() =>
//                 dispatch({ type: "SET_PRACTICE_AREA_INDEX", payload: 2 })
//               }
//             >
//               TWO
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() =>
//                 dispatch({ type: "SET_PRACTICE_AREA_INDEX", payload: 3 })
//               }
//             >
//               THREE
//             </button>
//           </li>
//         </ul>
//       </div>
//     </section>
//   );
// };

// export default PracticeAreasOverviewAccordion;

// ================================================================================================================
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStaticQuery, graphql, Link } from "gatsby";
import "./PracticeAreasOverviewAccordion.css";

const ServicesOverviewAccordion = () => {
  const [expandedIndex, setexpandedIndex] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      practiceAreasData: markdownRemark(
        frontmatter: { type: { eq: "business-information" } }
      ) {
        frontmatter {
          practiceAreas {
            practiceAreasList {
              overview
              iconPosition
              iconName
              heading
            }
            secondary
          }
        }
      }
    }
  `);

  return (
    <section className="container">
      <hr className="mt-4" style={{ backgroundColor: "grey" }}></hr>
      <h1>Providing Legal Solutions</h1>
      <div className="services-section">
        <div className="row">
          <p className="text-center fs-5">
            We offer services in the following areas:
          </p>
          <div className="services-main">
            <ul className="services-list">
              {data.practiceAreasData.frontmatter.practiceAreas.practiceAreasList.map(
                (item, index) => {
                  return (
                    <ServiceItem
                      key={item.heading}
                      title={item.heading}
                      text={item.overview}
                      iconText={item.iconName}
                      iconPosition={item.iconPosition}
                      index={index}
                      expandedIndex={expandedIndex}
                      expandItem={() => setexpandedIndex(index)}
                    />
                  );
                }
              )}
            </ul>
          </div>
          <p className="secondary position-relative w-75 text-center start-50">
            {data.practiceAreasData.frontmatter.practiceAreas.secondary}
          </p>
          <div className="mt-3 text-center cta-btn">
            <Link className="btn" to="/practice-areas/">
              Read more
            </Link>
          </div>
          <div className="services-gallery services-gallery-left">
            <img
              className="img-fluid services-pic"
              src="img/flag-gavel-image.jpg"
              alt=""
            />
            <img
              className="img-fluid services-pic"
              src="img/gavel-on-book.jpg"
              alt=""
            />
          </div>
          <div className="services-gallery services-gallery-right">
            <img
              className="img-fluid services-pic"
              src="img/portfolio-6.jpg"
              alt=""
            />
            <img
              className="img-fluid services-pic"
              src="img/portfolio-5.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOverviewAccordion;

const ServiceItem = ({
  title,
  text,
  iconText,
  iconPosition,
  index,
  expandedIndex,
  expandItem,
}) => {
  const detailsElem = useRef(null);
  const isExpanded = expandedIndex === index;

  useEffect(() => {
    detailsElem.current.open = isExpanded;
  }, [isExpanded]);

  return (
    <li key={title}>
      <details ref={detailsElem} onClick={expandItem}>
        <summary>{title}</summary>
        {isExpanded && <p>{text}</p>}
      </details>
      <FontAwesomeIcon
        icon={iconText}
        size="2x"
        width="1em"
        color="goldenrod"
        background="black"
        border-radius="50%"
        className={`services-icon ${iconPosition}`}
      />
    </li>
  );
};
