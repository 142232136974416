import React from "react";
import BlogPostCard from "./BlogPostCard";
import { Link, graphql, useStaticQuery } from "gatsby";
// import "./BlogListHome.css";

const BlogListHome = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "blog" } } }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 3
      ) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM, DD, YYYY")
              title
            }
            excerpt
            fields {
              slug
              readingTime {
                text
              }
            }
          }
        }
      }
      blogImage: file(name: { eq: "blog-article-default" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 20
            aspectRatio: 1.5
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <section className="blog-list-home-section container mt-3">
      <hr className="mt-4" style={{ backgroundColor: "grey" }}></hr>
      <h1>Latest Articles</h1>
      <ul className="row">
        {data.posts.edges.map((edge) => (
          <li key={edge.node.id} className="col-lg-4 mb-3">
            <BlogPostCard
              slug={edge.node.fields.slug}
              title={edge.node.frontmatter.title}
              date={edge.node.frontmatter.date}
              excerpt={edge.node.excerpt}
              blogImageSRC={data.blogImage.childImageSharp.gatsbyImageData}
              readingTime={edge.node.fields.readingTime.text}
            />
          </li>
        ))}
      </ul>
      <div className="mt-3 text-center cta-btn">
        <Link className="btn" to="/blogs/">
          Read more
        </Link>
      </div>
    </section>
  );
};
export default BlogListHome;
