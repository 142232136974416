import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import dateIcon from "../img/calendar.svg";
import timeIcon from "../img/watch.svg";
// import "./BlogListHome.css";

const BlogPostCard = ({
  title,
  date,
  excerpt,
  slug,
  blogImageSRC,
  readingTime,
}) => {
  return (
    <article
      className={`blog-list-item`}
      style={{
        backgroundColor: "whitesmoke",
        boxShadow: "0px 2px 10px rgba(9, 2, 2, 0.4)",
      }}
    >
      <header>
        <GatsbyImage image={blogImageSRC} alt="blog-image" />
        <h1 className="fs-4 mt-1 fw-semibold">
          <Link
            className="text-decoration-none blog-post-card-header"
            to={slug}
          >
            {title}
          </Link>
        </h1>
        <div
          className="mt-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid black",
          }}
        >
          <div
            className="mt-2 d-flex align-items-center"
            style={{ fontSize: "0.85em" }}
          >
            <img src={dateIcon} alt="date-icon" />
            <span className="ms-1">{date}</span>
          </div>
          <div
            className="mt-2 d-flex align-items-center"
            style={{ fontSize: "0.85em", marginLeft: "1.8em" }}
          >
            <img src={timeIcon} alt="reading-time-icon" />
            <span className="ms-1">{readingTime}</span>
          </div>
        </div>
      </header>
      <p className="mt-2">
        {excerpt}
        <br />
      </p>
      <Link className="fw-semibold button" to={slug}>
        Keep Reading →
      </Link>
    </article>
  );
};

export default BlogPostCard;
