import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import logo from "../img/logo.png";
import "./AboutOverview.css";

const AboutOverview = () => {
  const data = useStaticQuery(graphql`
    query AboutOverviewQuery {
      aboutOverviewImage: file(name: { eq: "lawyer-wig" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      aboutOverviewText: markdownRemark(
        frontmatter: { type: { eq: "business-information" } }
      ) {
        frontmatter {
          about {
            aboutOverviewText {
              description
              title
              stats {
                count
                description
              }
            }
          }
        }
      }
    }
  `);

  const {
    aboutOverviewText: {
      frontmatter: {
        about: { aboutOverviewText },
      },
    },
  } = data;

  const { description, title, stats: statsInfo } = aboutOverviewText;

  return (
    <section className="about-overview-section container">
      <div className="row">
        <div className="col-lg-7">
          <div className="about-overview position-relative">
            <h1 className="subsection-header title">{title}</h1>
            <p className="fs-5">{description}</p>
            <div className="stats-wrapper mt-5 row">
              {statsInfo.map((stat) => (
                <div className="col-md-4 col-12" key={stat.description}>
                  <Stat value={stat.count} description={stat.description} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="about-overview-pic">
            <div className="about-overview-pic-wrap">
              <GatsbyImage
                image={data.aboutOverviewImage.childImageSharp.gatsbyImageData}
                alt={"Lawyer wig"}
              />
              <div
                style={{
                  width: "fit-content",
                  position: "absolute",
                  zIndex: 200,
                  backgroundColor: "rgb(18, 21, 24)",
                  bottom: "0",
                  padding: "0.4em",
                }}
              >
                <img
                  className="img-fluid logo"
                  src={logo}
                  alt="Kingsbench Logo"
                  style={{ width: "3.8em", maxHeight: "initial" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOverview;

export const Stat = ({ value, description }) => {
  const [counter, setCounter] = useState(1);
  const [counterStopped, setCounterStopped] = useState(false);
  const [first, second] = description.split(" ");

  useEffect(() => {
    let intervalIdentifier = setInterval(() => {
      setCounter((c) => c + 1);
    }, 20);
    if (counterStopped) {
      // console.log("COUNTER STOPPED!!")
      clearInterval(intervalIdentifier);
    }

    return () => {
      clearInterval(intervalIdentifier);
    };
  }, [counterStopped]);

  useEffect(() => {
    if (counter === value) setCounterStopped(true);
  }, [counter, value]);

  //////////////////////////////////////////// THIS WORKED ////////////////////////////////////////
  // useEffect(() => {
  //   let interval = setTimeout(() => {
  //     setCounter((c) => c + 1);
  //   }, 20);
  //   if (counter === value) {clearTimeout(interval); console.info("STOPPING")};
  //   console.info("Component is re-rendered and side-effect function is updated with a new value")
  //   // return () => {
  //   //   // console.info("CLEANUP FUNCTION CALLED!")
  //   //   clearTimeout(interval);
  //   // };
  // }, [counter]);

  //////////////////////////////////////////// THIS DIDNT WORK OUT QUITE WELL ////////////////////////////////////////
  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     if(counter < value){
  //       setCounter((c) => c + 1);
  //     }else{
  //       console.info("CLEARING")
  //       clearInterval(interval)
  //     }
  //   }, 20);
  //   // if (counter === value) {clearInterval(interval); console.info("STOPPING")};
  //   console.info("Component is simply rendered and this function isn't called anymore")

  //   // return () => {
  //   //   // console.info("CLEANUP FUNCTION CALLED!")
  //   //   clearTimeout(interval);
  //   // };
  // }, []);

  return (
    <div className="about-stats">
      <h4>
        <span className="counter">{counter}</span>
        <span> + </span>
      </h4>
      <div className="d-flex flex-column">
        <div className="stats-description stats-description--mobile">
          <span>{first}</span>&nbsp;<span>{second}</span>
        </div>
        <div className="stats-description stats-description--wider-screen">
          <div>{first}</div>
          <div>{second}</div>
        </div>
      </div>
    </div>
  );
};
