import React from "react";
import Layout from "components/Layout";
import PracticeAreasOverviewAccordion from "components/PracticeAreasOverviewAccordion";
import AboutOverview from "components/AboutOverview";
import BlogListHome from "components/BlogListHome";

export default function IndexPage() {
  return (
    <Layout>
      <section className="my-4">
        <AboutOverview />
        <PracticeAreasOverviewAccordion />
        <BlogListHome />
      </section>
    </Layout>
  );
}
